.searchBar {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 5px;
  border: 1px solid var(--color-border-light);
  gap: 10px;
  white-space: nowrap;
  justify-content: space-between;
  width: 100%;
}

.searchBar form {
  flex-grow: 1;
}

.searchInput {
  height: inherit;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  font-size: 1.25rem;
}

.searchButton {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
}

.searchIcon {
  height: 24px;
}
