.navigationContainer {
  display: none;
  white-space: nowrap;
}

.navigationContainer.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: inherit;
  padding: 20px;
}

.linksWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  border-bottom: 1px solid var(--color-border-light);
}

.switchWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.userProfile {
  font-style: italic;
}

.userProfile span {
  font-weight: bold;
}

/* Media query for larger screens */
@media screen and (min-width: 1024px) {
  .navigationContainer,
  .navigationContainer.active {
    position: static;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: inherit;
    background-color: var(--color-background);
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }

  .linksWrapper {
    flex-direction: row;
    border: 0;
  }
}
