.errorContainer {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 0 auto;
}

.errorContainer img {
  border: 1px solid var(--color-border-dark);
  width: 100%;
}
